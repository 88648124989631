import React from 'react';
import {BooleanInput, DateInput, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin'

const CookSubscriptionV3Edit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <BooleanInput source="active"/>
                <TextInput source='cookId' />
                <ReferenceInput label="Subscription" source="subscriptionId" reference="SubscriptionV3">
                    <SelectInput optionText='id' />
                </ReferenceInput>
                <ReferenceInput label="User Bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <SelectInput optionText='id' />
                </ReferenceInput>
                <DateInput source="startDate" />
                <TextInput source="startSlot" />
                <DateInput source="endDate" />
                <TextInput source="endSlot" />
                <TextInput source="leaveTaken" />
                <TextInput source="leaveAllowed" />
            </SimpleForm>
        </Edit>
    );
};

export default CookSubscriptionV3Edit;