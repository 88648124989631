import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    DeleteButton,
    ShowButton,
    ReferenceField
} from 'react-admin';

const HouseWalletTransaction = (props: any) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source='id' />
                <ReferenceField label="User Bundle Mapping" reference="UserBundleMapping" source="userBundleMappingId" >
                    <TextField source="status"/>
                </ReferenceField>
                <ReferenceField label="Wallet" reference="HouseWallet" source="walletId">
                    <TextField source="amount"/>
                </ReferenceField>
                <ReferenceField label="Subscription" reference="SubscriptionV3" source="subscriptionId">
                    <TextField source="status"/>
                </ReferenceField>
                <TextField source="amount" />
                <TextField source="totalBalanceAfter" />
                <TextField source="userId" />
                <TextField source="reason" />
                <DeleteButton/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default HouseWalletTransaction;