import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    DeleteButton,
    ShowButton,
    ReferenceField,
    DateField,
} from 'react-admin';

const SubscriptionV3 = (props: any) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source='id' />
                <ReferenceField label="User Bundle" source="userBundleMappingId" reference="UserBundleMapping">
                    <TextField source="status"/>
                </ReferenceField> 
                <DateField source='startDate' />
                <TextField source="startSlot" />
                <DateField source='endDate' />
                <TextField source="endSlot" />
                <TextField source="status" />
                <TextField source="type" />
                <TextField source="price" />
                <TextField source="discount" />
                <TextField source="netPrice"  />
                <TextField source="mealMapping" />
                <TextField source="totalLeaves" />
                <TextField source="totalVisits" />
                <TextField source="createdBy" />
                <TextField source="totalMeal" />
                <DeleteButton/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default SubscriptionV3;