import React from 'react';
import {
    SimpleShowLayout,
    TextField,
    Show,
} from 'react-admin';

const ValueAddedServiceShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <TextField source='name' />
                <TextField source='description' />
            </SimpleShowLayout>
        </Show>
    );
};

export default ValueAddedServiceShow;