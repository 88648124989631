import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

const AuthAdminUserShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id'/>
                <TextField source='name'/>
                <TextField source='email'/>
            </SimpleShowLayout>
        </Show>
    );
};

export default AuthAdminUserShow;