import axios from "axios";
import {AuthService} from "./services/AuthService";

let http = axios.create({
    baseURL: process.env.REACT_APP_PUBLIC_URL,
    headers: {
        'Content-Type': 'application/json',
        'X-APP-CODE': 'ADMIN',
    }
});

http.interceptors.request.use((req) => {
    let authData = localStorage.getItem('auth');
    const { accessToken } = !!authData ? JSON.parse(authData as string).data : '';
    req.headers = {
        ...req.headers,
        'Authorization': `Bearer ${accessToken}`,
    }
    return req
})

http.interceptors.response.use(
    (res) => res,
    async (err) => {
        if (err.request && err.request.status === 401) {
            const authService = new AuthService();
            let success = await authService.refreshToken();
            if (!success) {
                localStorage.removeItem('auth');
            }
        }
    }
)

export default http;