import React from 'react';
import {Edit, SimpleForm, TextInput} from 'react-admin'

const ApplicationEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="name"/>
                <TextInput source="fcmCredentials" />
                <TextInput source="data" />
            </SimpleForm>
        </Edit>
    );
};

export default ApplicationEdit;