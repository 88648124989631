import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    DeleteButton,
    ShowButton,
    ReferenceField,
} from 'react-admin';

const UserBundleMapping = (props: any) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source='id' />
                <ReferenceField label="User Bundle" source="userBundleMappingId" reference="UserBundleMapping">
                    <TextField source="status"/>
                </ReferenceField> 
                <TextField source='plan' />
                <TextField source="genderPreference" />
                <TextField source="totalPeople" />
                <TextField source="mealMapping" />
                <TextField source="totalVisits" />
                <TextField source="totalLeaves"  />
                <TextField source="takenLeaves" />
                <TextField source="utensils"  />
                <DeleteButton/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default UserBundleMapping;