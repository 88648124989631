import React from 'react';
import {
    SimpleShowLayout,
    TextField,
    ReferenceField,
    Show,
    DateField,
} from 'react-admin';

const SubscriptionV3Show = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <ReferenceField label="User Bundle" source="userBundleMappingId" reference="UserBundleMapping">
                    <TextField source="status"/>
                </ReferenceField> 
                <DateField source='startDate' />
                <TextField source="startSlot" />
                <DateField source='endDate' />
                <TextField source="endSlot" />
                <TextField source="status" />
                <TextField source="type" />
                <TextField source="price" />
                <TextField source="discount" />
                <TextField source="netPrice"  />
                <TextField source="mealMapping" />
                <TextField source="totalLeaves" />
                <TextField source="totalVisits" />
                <TextField source="createdBy" />
                <TextField source="totalMeal" />
            </SimpleShowLayout>
        </Show>
    );
};

export default SubscriptionV3Show;