export enum TemplateSMSTypes { 
    PROMOTIONAL   = "PROMOTIONAL",
    TRANSACTIONAL = "TRANSACTIONAL"
}

export enum Priority { 
    LOW = "LOW",
    NORMAL = "NORMAL",
    HIGH = "HIGH"
}

export enum TemplateVariantLanguages { 
    ENGLISH   = "ENGLISH",
    HINDI     = "HINDI",
    BENGALI   = "BENGALI",
    MARATHI   = "MARATHI",
    TELUGU    = "TELUGU",
    TAMIL     = "TAMIL",
    GUJRATI   = "GUJRATI",
    URDU      = "URDU",
    KANNADA   = "KANNADA",
    ODIA      = "ODIA",
    MALAYALAM = "MALAYALAM",
    PUNJABI   = "PUNJABI",
    ASSAMESE  = "ASSAMESE"
}

export enum SlotsEnum {
    B= 'B',
    L='L',
    D='D',
    BL='BL',
    BD='BD',
    LD='LD',
    BLD='BLD'
}

export enum TempReplacementStatus {
    PENDING = "PENDING",
    FULFILLED = "FULFILLED",
    CANCELLED =  "CANCELLED"
}

export const TempReplacementStatusArray = [
    { id: "PENDING", name: "PENDING" },
    { id: "FULFILLED", name: "FULFILLED" },
    { id: "CANCELLED", name: "CANCELLED" }
]


export const SlotsArray = [
    { id: "B", name: "B" },
    { id: "L", name: "L" },
    { id: "D", name: "D" },
    { id: "BL", name: "BL" },
    { id: "BD", name: "BD" },
    { id: "LD", name: "LD"},
    { id: "BLD", name: "BLD" }
]

export enum UserBundleMappingState {
    ACTIVE = 'ACTIVE',
    PAUSED = 'PAUSED',
}

export enum UserBundleMappingStatus {
    PENDING = "PENDING",
    ACTIVE = "ACTIVE", 
    UPCOMING = "UPCOMING",
    FULFILLED =  "FULFILLED",
    CLOSED = "CLOSED",
    CANCELLED = "CANCELLED"
}

export enum UserType {
    ADMIN ='ADMIN',
    CUSTOMER ='CUSTOMER',
    COOK = 'COOK',
    INTERNAL_SERVICES = 'INTERNAL_SERVICES',
};

export enum BundlePlan { 
    PREMIUM = "PREMIUM",
    NORMAL =  "NORMAL"
};

export enum BundleGenderPreference { 
    MALE = "MALE",
    FEMALE =  "FEMALE",
    ANY = "ANY"
};

export enum SubscriptionStatus { 
    ACTIVE = "ACTIVE",
    UPCOMING = "UPCOMING",
    FULFULLED = "FULFULLED",
    CLOSED = "CLOSED",
    PARTIALLY_CLOSED = "PARTIALLY_CLOSED",
    CANCELLED = "CANCELLED"
};

export enum SubscriptionTypes { 
    PAID = "PAID",
    FREE = "FREE",
    GRACE = "GRACE"
}

export enum BundleMetaChangeHistoryState {
    PENDING = "PENDING",
    PROGRESS = "PROGRESS",
    PROCESSED = "PROCESSED"
}

export const mealMapping = [
    { id: 'B', name: 'B' },
    { id: 'L', name: 'L' },
    { id: 'D', name: 'D' },
    { id: 'BL', name: 'BL' },
    { id: 'LD', name: 'LD' },
    { id: 'BLD', name: 'BLD' },
]

export const plan = [
    { id: 'PREMIUM', name: 'PREMIUM' },
    { id: 'NORMAL', name: 'NORMAL' }
]

export const GenderPreference = [
    { id: 'MALE', name: 'MALE' },
    { id: 'FEMALE', name: 'FEMALE' }
]

export const createdByType = [
    { id: 'ADMIN', name: 'ADMIN' },
    { id: 'CUSTOMER', name: 'CUSTOMER' },
    { id: 'COOK', name: 'COOK' },
    { id: 'INTERNAL_SERVICES', name: 'INTERNAL_SERVICES' }
];

export const state = [
    { id: 'ACTIVE', name: 'ACTIVE' },
    { id: 'PAUSED', name: 'PAUSED' },
]

export enum UserServiceTransactionState {
    PENDING = "PENDING",
    CANCELLED =  "CANCELLED",
    COMPLETED =  "COMPLETED"
}

export const UserServiceTransactionStateArray = [
    { id: "PENDING", name: "PENDING" },
    { id: "CANCELLED", name: "CANCELLED" },
    { id: "COMPLETED", name: "COMPLETED" }
]

export enum TimeEnums {
    AM_00_00 = '00:00 AM',
    AM_00_30 = '00:30 AM',
    AM_01_00 = '01:00 AM',
    AM_01_30 = '01:30 AM',
    AM_02_00 = '02:00 AM',
    AM_02_30 = '02:30 AM',
    AM_03_00 = '03:00 AM',
    AM_03_30 = '03:30 AM',
    AM_04_00 = '04:00 AM',
    AM_04_30 = '04:30 AM',
    AM_05_00 = '05:00 AM',
    AM_05_30 = '05:30 AM',
    AM_06_00 = '06:00 AM',
    AM_06_30 = '06:30 AM',
    AM_07_00 = '07:00 AM',
    AM_07_30 = '07:30 AM',
    AM_08_00 = '08:00 AM',
    AM_08_30 = '08:30 AM',
    AM_09_00 = '09:00 AM',
    AM_09_30 = '09:30 AM',
    AM_10_00 = '10:00 AM',
    AM_10_30 = '10:30 AM',
    AM_11_00 = '11:00 AM',
    AM_11_30 = '11:30 AM',
    PM_12_00 = '12:00 PM',
    PM_12_30 = '12:30 PM',
    PM_01_00 = '01:00 PM',
    PM_01_30 = '01:30 PM',
    PM_02_00 = '02:00 PM',
    PM_02_30 = '02:30 PM',
    PM_03_00 = '03:00 PM',
    PM_03_30 = '03:30 PM',
    PM_04_00 = '04:00 PM',
    PM_04_30 = '04:30 PM',
    PM_05_00 = '05:00 PM',
    PM_05_30 = '05:30 PM',
    PM_06_00 = '06:00 PM',
    PM_06_30 = '06:30 PM',
    PM_07_00 = '07:00 PM',
    PM_07_30 = '07:30 PM',
    PM_08_00 = '08:00 PM',
    PM_08_30 = '08:30 PM',
    PM_09_00 = '09:00 PM',
    PM_09_30 = '09:30 PM',
    PM_10_00 = '10:00 PM',
    PM_10_30 = '10:30 PM',
    PM_11_00 = '11:00 PM',
    PM_11_30 = '11:30 PM'
}

export const TimeEnumsArray = [
        { name: "AM_00_00", id: '00:00 AM'},
        { name: "AM_00_30", id: '00:30 AM'},
        { name: "AM_01_00", id: '01:00 AM'},
        { name: "AM_01_30", id: '01:30 AM'},
        { name: "AM_02_00", id: '02:00 AM'},
        { name: "AM_02_30", id: '02:30 AM'},
        { name: "AM_03_00", id: '03:00 AM'},
        { name: "AM_03_30", id: '03:30 AM'},
        { name: "AM_04_00", id: '04:00 AM'},
        { name: "AM_04_30", id: '04:30 AM'},
        { name: "AM_05_00", id: '05:00 AM'},
        { name: "AM_05_30", id: '05:30 AM'},
        { name: "AM_06_00", id: '06:00 AM'},
        { name: "AM_06_30", id: '06:30 AM'},
        { name: "AM_07_00", id: '07:00 AM'},
        { name: "AM_07_30", id: '07:30 AM'},
        { name: "AM_08_00", id: '08:00 AM'},
        { name: "AM_08_30", id: '08:30 AM'},
        { name: "AM_09_00", id: '09:00 AM'},
        { name: "AM_09_30", id: '09:30 AM'},
        { name: "AM_10_00", id: '10:00 AM'},
        { name: "AM_10_30", id: '10:30 AM'},
        { name: "AM_11_00", id: '11:00 AM'},
        { name: "AM_11_30", id: '11:30 AM'},
        { name: "PM_12_00", id: '12:00 PM'},
        { name: "PM_12_30", id: '12:30 PM'},
        { name: "PM_01_00", id: '01:00 PM'},
        { name: "PM_01_30", id: '01:30 PM'},
        { name: "PM_02_00", id: '02:00 PM'},
        { name: "PM_02_30", id: '02:30 PM'},
        { name: "PM_03_00", id: '03:00 PM'},
        { name: "PM_03_30", id: '03:30 PM'},
        { name: "PM_04_00", id: '04:00 PM'},
        { name: "PM_04_30", id: '04:30 PM'},
        { name: "PM_05_00", id: '05:00 PM'},
        { name: "PM_05_30", id: '05:30 PM'},
        { name: "PM_06_00", id: '06:00 PM'},
        { name: "PM_06_30", id: '06:30 PM'},
        { name: "PM_07_00", id: '07:00 PM'},
        { name: "PM_07_30", id: '07:30 PM'},
        { name: "PM_08_00", id: '08:00 PM'},
        { name: "PM_08_30", id: '08:30 PM'},
        { name: "PM_09_00", id: '09:00 PM'},
        { name: "PM_09_30", id: '09:30 PM'},
        { name: "PM_10_00", id: '10:00 PM'},
        { name: "PM_10_30", id: '10:30 PM'},
        { name: "PM_11_00", id: '11:00 PM'},
        { name: "PM_11_30", id: '11:30 PM'}
]

export enum UserSubscriptionLeaveType {
    PAST = "PAST",
    FUTURE =  "FUTURE",
    PRESENT =  "PRESENT"
}

export const UserSubscriptionLeaveTypeArray = [
    { id: "PAST ", name: "PAST" },
    { id: "FUTURE", name: "FUTURE" },
    { id: "PRESENT", name: "PRESENT" }
]

export enum UserSubscriptionLeaveResolution {
    NONE = "NONE",
    EXTENSION =  "EXTENSION",
    TR =  "TR"
}

export const UserSubscriptionLeaveResolutionArray = [
    { id : "NONE", name: "NONE"},
    { id : "EXTENSION", name:  "EXTENSION"},
    { id : "TR", name:  "TR"}
]

export enum ExtensionStatus {
    GRANTED = "GRANTED",
    CANCELLED =  "CANCELLED"
}

export const ExtensionStatusArray = [
    { id: "GRANTED", name: "GRANTED" },
    { id: "CANCELLED", name: "CANCELLED" }
]