import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    DeleteButton,
    ShowButton,
    ReferenceField,
    DateField,
} from 'react-admin';

const TempReplacement = (props: any) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source='id' />
                <ReferenceField label="User Subscription Leave" source="userSubscriptionLeaveId" reference="UserSubscriptionLeaveMarked" link="show">
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceField label="Subscription" source="subscriptionId" reference="SubscriptionV3" link="show">
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceField label="User Bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping" link="show">
                    <TextField source="id" />
                </ReferenceField>
                <TextField source='cookId' />
                <DateField source='date' />
                <TextField source='time' />
                <TextField source='slot' />
                <TextField source='comment' />
                <TextField source='status'/>
                <DeleteButton/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default TempReplacement;