import React from 'react';
import {Admin, Resource} from "react-admin";
import customDataProvider from "./dataProvider";
import AppConstants from './components/AppConstants/AppConstants';
import AppConstantsCreate from "./components/AppConstants/AppConstantsCreate";
import authProvider from "./authProvider";
import AppConstantsEdit from "./components/AppConstants/AppConstantsEdit";
import AppConstantsShow from "./components/AppConstants/AppConstantsShow";
import SMS from './components/SMS/SMS';
import Template from './components/Templates/Template';
import TemplateVariant from './components/TemplateVariants/TemplateVariant';
import TemplateCreate from './components/Templates/TemplateCreate';
import TemplateShow from './components/Templates/TemplateShow';
import TemplateEdit from './components/Templates/TemplateEdit';
import Application from './components/Application/Application';
import ApplicationCreate from './components/Application/ApplicationCreate';
import ApplicationEdit from './components/Application/ApplicationEdit';
import ApplicationShow from './components/Application/ApplicationShow';
import TemplateVariantCreate from './components/TemplateVariants/TemplateVariantCreate';
import TemplateVariantEdit from './components/TemplateVariants/TemplateVariantEdit';
import TemplateVariantShow from './components/TemplateVariants/TemplateVariantShow';
import SMSCreate from './components/SMS/SMSCreate';
import SMSEdit from './components/SMS/SMSEdit';
import SMSShow from './components/SMS/SMSShow';
import Bundle from './components/Bundles/Bundle';
import BundleCreate from './components/Bundles/BundleCreate';
import BundleEdit from './components/Bundles/BundleEdit';
import BundleShow from './components/Bundles/BundleShow';
import UserBundleMappingCreate from './components/UserBundleMappings/UserBundleMappingCreate';
import UserBundleMappingShow from './components/UserBundleMappings/UserBundleMappingShow';
import UserBundleMappingEdit from './components/UserBundleMappings/UserBundleMappingEdit';
import UserBundleMapping from './components/UserBundleMappings/UserBundleMapping';
import UserBundleMeta from './components/UserBundleMetas/UserBundleMappings/UserBundleMeta';
import UserBundleMetaCreate from './components/UserBundleMetas/UserBundleMappings/UserBundleMetaCreate';
import UserBundleMetaEdit from './components/UserBundleMetas/UserBundleMappings/UserBundleMetaEdit';
import UserBundleMetaShow from './components/UserBundleMetas/UserBundleMappings/UserBundleMetaShow';
import SubscriptionV3 from './components/SubscriptionV3/SubscriptionV3';
import SubscriptionV3Create from './components/SubscriptionV3/SubscriptionV3Create';
import SubscriptionV3Edit from './components/SubscriptionV3/SubscriptionV3Edit';
import SubscriptionV3Show from './components/SubscriptionV3/SubscriptionV3Show';
import BundleMetaChangeHistory from './components/BundleMetaChangeHistory/BundleMetaChangeHistory';
import BundleMetaChangeHistoryCreate from './components/BundleMetaChangeHistory/BundleMetaChangeHistoryCreate';
import BundleMetaChangeHistoryEdit from './components/BundleMetaChangeHistory/BundleMetaChangeHistoryEdit';
import BundleMetaChangeHistoryShow from './components/BundleMetaChangeHistory/BundleMetaChangeHistoryShow';
import HouseWallet from './components/HouseWallet/HouseWallet';
import HouseWalletCreate from './components/HouseWallet/HouseWalletCreate';
import HouseWalletEdit from './components/HouseWallet/HouseWalletEdit';
import HouseWalletShow from './components/HouseWallet/HouseWalletShow';
import HouseWalletTransaction from './components/HouseWalletTransaction/HouseWalletTransaction';
import HouseWalletTransactionCreate from './components/HouseWalletTransaction/HouseWalletTransactionCreate';
import HouseWalletTransactionEdit from './components/HouseWalletTransaction/HouseWalletTransactionEdit';
import HouseWalletTransactionShow from './components/HouseWalletTransaction/HouseWalletTransactionShow';
import ValueAddedServiceShow from './components/ValueAddedServices/ValueAddedServiceShow';
import ValueAddedServiceEdit from './components/ValueAddedServices/ValueAddedServiceEdit';
import ValueAddedServiceCreate from './components/ValueAddedServices/ValueAddedServiceCreate';
import ValueAddedService from './components/ValueAddedServices/ValueAddedService';
import UserBundleServiceMapping from './components/UserBundleServiceMapping/UserBundleServiceMapping';
import UserBundleServiceMappingCreate from './components/UserBundleServiceMapping/UserBundleServiceMappingCreate';
import UserBundleServiceMappingEdit from './components/UserBundleServiceMapping/UserBundleServiceMappingEdit';
import UserBundleServiceMappingShow from './components/UserBundleServiceMapping/UserBundleServiceMappingShow';
import UserServiceTransaction from './components/UserServiceTransaction/UserServiceTransaction';
import UserServiceTransactionCreate from './components/UserServiceTransaction/UserServiceTransactionCreate';
import UserServiceTransactionEdit from './components/UserServiceTransaction/UserServiceTransactionEdit';
import UserServiceTransactionShow from './components/UserServiceTransaction/UserServiceTransactionShow';
import CookSubscriptionV3 from './components/CookSubscriptionV3/CookSubscriptionV3';
import CookSubscriptionV3Create from './components/CookSubscriptionV3/CookSubscriptionV3Create';
import CookSubscriptionV3Edit from './components/CookSubscriptionV3/CookSubscriptionV3Edit';
import CookSubscriptionV3Show from './components/CookSubscriptionV3/CookSubscriptionV3Show';
import CookSubscriptionCheckInConfiguration from './components/CookSubscriptionCheckInConfiguration/CookSubscriptionCheckInConfiguration';
import CookSubscriptionCheckInConfigurationCreate from './components/CookSubscriptionCheckInConfiguration/CookSubscriptionCheckInConfigurationCreate';
import CookSubscriptionCheckInConfigurationEdit from './components/CookSubscriptionCheckInConfiguration/CookSubscriptionCheckInConfigurationEdit';
import CookSubscriptionCheckInConfigurationShow from './components/CookSubscriptionCheckInConfiguration/CookSubscriptionChekInConfigurationShow';
import CookSubscriptionSlotMapping from './components/CookSubscriptionSlotMapping/CookSubscriptionSlotMapping';
import CookSubscriptionSlotMappingCreate from './components/CookSubscriptionSlotMapping/CookSubscriptionSlotMappingCreate';
import CookSubscriptionSlotMappingEdit from './components/CookSubscriptionSlotMapping/CookSubscriptionSlotMappingEdit';
import CookSubscriptionSlotMappingShow from './components/CookSubscriptionSlotMapping/CookSubscriptionSlotMappingShow';
import UserSubscriptionLeaveMarked from './components/UserSubscriptionLeaveMarked/UserSubscriptionLeaveMarked';
import UserSubscriptionLeaveMarkedEdit from './components/UserSubscriptionLeaveMarked/UserSubscriptionLeaveMarkedEdit';
import UserSubscriptionLeaveMarkedShow from './components/UserSubscriptionLeaveMarked/UserSubscriptionLeaveMarkedShow';
import UserSubscriptionLeaveMarkedCreate from './components/UserSubscriptionLeaveMarked/UserSubscriptionLeaveMarkedCreate';
import TempReplacement from './components/TempReplacement/TempReplacement';
import TempReplacementCreate from './components/TempReplacement/TempReplacementCreate';
import TempReplacementEdit from './components/TempReplacement/TempReplacementEdit';
import TempReplacementShow from './components/TempReplacement/TempReplacementShow';
import Vendor from './components/Vendors/Vendor';
import VendorCreate from './components/Vendors/VendorCreate';
import VendorEdit from './components/Vendors/VendorEdit';
import VendorShow from './components/Vendors/VendorShow';
import Extension from './components/Extensions/Extension';
import ExtensionCreate from './components/Extensions/ExtensionCreate';
import ExtensionEdit from './components/Extensions/ExtensionEdit';
import ExtensionShow from './components/Extensions/ExtensionShow';
import AuthAdminUser from './components/AuthAdminUser/AuthAdminUser';
import AuthAdminUserEdit from './components/AuthAdminUser/AuthAdminUserEdit';
import AuthAdminUserCreate from './components/AuthAdminUser/AuthAdminUserCreate';
import AuthAdminUserShow from './components/AuthAdminUser/AuthAdminUserShow';

function App() {
    return (
        <Admin dataProvider={customDataProvider} authProvider={authProvider}>
            <Resource
                name='AppConstants'
                list={AppConstants}
                create={AppConstantsCreate}
                edit={AppConstantsEdit}
                show={AppConstantsShow}
            />
            <Resource
                name='AuthAdminUsers'
                list={AuthAdminUser}
                create={AuthAdminUserCreate}
                edit={AuthAdminUserEdit}
                show={AuthAdminUserShow}
            />
            <Resource
                name='Application'
                list={Application}
                create={ApplicationCreate}
                edit={ApplicationEdit}
                show={ApplicationShow}
            />

            <Resource
                name='SMS'
                list={SMS}
                create={SMSCreate}
                edit={SMSEdit}
                show={SMSShow}
            />

            <Resource
                name='Template'
                list={Template}
                create={TemplateCreate}
                edit={TemplateEdit}
                show={TemplateShow}
            />

            <Resource
                name='TemplateVariant'
                list={TemplateVariant}
                create={TemplateVariantCreate}
                edit={TemplateVariantEdit}
                show={TemplateVariantShow}
            />
             <Resource
                name='Bundle'
                list={Bundle}
                create={BundleCreate}
                edit={BundleEdit}
                show={BundleShow}
            />
            <Resource
                name='UserBundleMapping'
                list={UserBundleMapping}
                create={UserBundleMappingCreate}
                edit={UserBundleMappingEdit}
                show={UserBundleMappingShow}
            />
            <Resource
                name='UserBundleMeta'
                list={UserBundleMeta}
                create={UserBundleMetaCreate}
                edit={UserBundleMetaEdit}
                show={UserBundleMetaShow}
            />
            <Resource
                name='SubscriptionV3'
                list={SubscriptionV3}
                create={SubscriptionV3Create}
                edit={SubscriptionV3Edit}
                show={SubscriptionV3Show}
            />
            <Resource
                name='BundleMetaChangeHistory'
                list={BundleMetaChangeHistory}
                create={BundleMetaChangeHistoryCreate}
                edit={BundleMetaChangeHistoryEdit}
                show={BundleMetaChangeHistoryShow}
            />
            <Resource
                name='HouseWallet'
                list={HouseWallet}
                create={HouseWalletCreate}
                edit={HouseWalletEdit}
                show={HouseWalletShow}
            />
            <Resource
                name='HouseWalletTransaction'
                list={HouseWalletTransaction}
                create={HouseWalletTransactionCreate}
                edit={HouseWalletTransactionEdit}
                show={HouseWalletTransactionShow}
            />
            <Resource
                name='ValueAddedService'
                list={ValueAddedService}
                create={ValueAddedServiceCreate}
                edit={ValueAddedServiceEdit}
                show={ValueAddedServiceShow}
            />
            <Resource
                name='UserBundleServiceMapping'
                list={UserBundleServiceMapping}
                create={UserBundleServiceMappingCreate}
                edit={UserBundleServiceMappingEdit}
                show={UserBundleServiceMappingShow}
            />
            <Resource
                name='UserServiceTransaction'
                list={UserServiceTransaction}
                create={UserServiceTransactionCreate}
                edit={UserServiceTransactionEdit}
                show={UserServiceTransactionShow}
            />
            <Resource
                name='CookSubscriptionV3'
                list={CookSubscriptionV3}
                create={CookSubscriptionV3Create}
                edit={CookSubscriptionV3Edit}
                show={CookSubscriptionV3Show}
            />
            <Resource
                name='CookSubscriptionCheckInConfiguration'
                list={CookSubscriptionCheckInConfiguration}
                create={CookSubscriptionCheckInConfigurationCreate}
                edit={CookSubscriptionCheckInConfigurationEdit}
                show={CookSubscriptionCheckInConfigurationShow}
            />
            <Resource
                name='CookSubscriptionSlotMapping'
                list={CookSubscriptionSlotMapping}
                create={CookSubscriptionSlotMappingCreate}
                edit={CookSubscriptionSlotMappingEdit}
                show={CookSubscriptionSlotMappingShow}
            />
            <Resource
                name='UserSubscriptionLeaveMarked'
                list={UserSubscriptionLeaveMarked}
                create={UserSubscriptionLeaveMarkedCreate}
                edit={UserSubscriptionLeaveMarkedEdit}
                show={UserSubscriptionLeaveMarkedShow}
            />
            <Resource
                name='TempReplacement'
                list={TempReplacement}
                create={TempReplacementCreate}
                edit={TempReplacementEdit}
                show={TempReplacementShow}
            />
            <Resource
                name='Vendor'
                list={Vendor}
                create={VendorCreate}
                edit={VendorEdit}
                show={VendorShow}
            />
            <Resource
                name='Extension'
                list={Extension}
                create={ExtensionCreate}
                edit={ExtensionEdit}
                show={ExtensionShow}
            />
        </Admin>
    );
}

export default App;
