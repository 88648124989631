import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, DateInput } from 'react-admin';
import { TimeEnumsArray, UserServiceTransactionStateArray } from '../../interfaces/CommonInterfaces/Common';

const UserBudleServiceMappingCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput label="User Service bundle Mapping" source="userServiceBundleMappingId" reference="UserBundleServiceMapping">
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <SelectInput source="state" choices={UserServiceTransactionStateArray}/>
                <DateInput source="date"/>
                <SelectInput source="time" choices={TimeEnumsArray}/>
                <TextInput source="userId" />
                <TextInput source="userType" />
                <TextInput source="commentFromUser" />
                <TextInput source="commentFromAdmin" />
            </SimpleForm>
        </Create>
    );
};

export default UserBudleServiceMappingCreate;