import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, DateInput } from 'react-admin';

const UserBudleMappingCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput label="User Bundle" source="userBundleMappingId" reference="UserBundleMapping">
                    <SelectInput optionText="id"/>
                </ReferenceInput> 
                <DateInput source='startDate' />
                <TextInput source="startSlot" />
                <DateInput source='endDate' />
                <TextInput source="endSlot" />
                <SelectInput source='status' choices={[
                    { id: 'PENDING', name: 'PENDING' },
                    { id: 'ACTIVE', name: 'ACTIVE' },
                    { id: 'UPCOMING', name: 'UPCOMING' },
                    { id: 'FULFILLED', name: 'FULFILLED' },
                    { id: 'CLOSED', name: 'CLOSED' },
                    { id: 'CANCELLED', name: 'CANCELLED' }
                ]} />
                <TextInput source="type" />
                <TextInput source="price" />
                <TextInput source="discount" />
                <TextInput source="netPrice"  />
                <SelectInput source="mealMapping" choices={[
                    { id: 'B', name: 'B' },
                    { id: 'L', name: 'L' },
                    { id: 'D', name: 'D' },
                    { id: 'BL', name: 'BL' },
                    { id: 'LD', name: 'LD' },
                    { id: 'BLD', name: 'BLD' },
                ]} />
                <TextInput source="totalLeaves" />
                <TextInput source="totalVisits" />
                <TextInput source="createdBy" />
                <TextInput source="totalMeal" />
            </SimpleForm>
        </Create>
    );
};

export default UserBudleMappingCreate;