import React from 'react';
import {ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';

const TemplateShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id'/>
                <TextField source='name'/>
                <ReferenceField label="App" source="app" reference="Application" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source='SMSType' label="Type"/>,
                <TextField source='SMSPriority' label="Priority"/>
            </SimpleShowLayout>
        </Show>
    );
};

export default TemplateShow;