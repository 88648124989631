import React from 'react';
import { Edit, SimpleForm, TextInput} from 'react-admin'

const ValueAddedServiceEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source='name' />
                <TextInput source='description' />
            </SimpleForm>
        </Edit>
    );
};

export default ValueAddedServiceEdit;