import React from 'react';
import {Edit, SimpleForm, TextInput} from 'react-admin'

const SMSEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="houseId" />
                <TextInput source="amount" />
            </SimpleForm>
        </Edit>
    );
};

export default SMSEdit;