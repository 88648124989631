import React from 'react';
import { Create,  ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { ExtensionStatusArray } from '../../interfaces/CommonInterfaces/Common';

const ExtensionCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput label="Subscription" source="subscriptionId" reference="SubscriptionV3">
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <ReferenceInput label="User Bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <TextInput source='visits' />
                <ReferenceInput label="User Subscription leave Marked" source="userSubscriptionLeaveId" reference="UserSubscriptionLeaveMarked">
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <TextInput source='reason' />
                <TextInput source='comments' />
                <SelectInput source='status' choices={ ExtensionStatusArray }/>
                <TextInput source="cancellationReason"/>
            </SimpleForm>
        </Create>
    );
};

export default ExtensionCreate;