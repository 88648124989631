import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    DeleteButton,
    ShowButton,
    ReferenceField
} from 'react-admin';

const Template = (props: any) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source='id'/>
                <TextField source='name'/>
                <ReferenceField label="App" source="app" reference="Application" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source='SMSType' label="Type"/>,
                <TextField source='SMSPriority' label="Priority"/>
                <DeleteButton/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default Template;