import React from 'react';
import {Show, SimpleShowLayout, TextField} from 'react-admin';

const AppConstantsShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="name"/>
                <TextField source="value"/>
            </SimpleShowLayout>
        </Show>
    );
};

export default AppConstantsShow;