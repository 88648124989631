import React from 'react';
import {
    TextField,
    ReferenceField,
    Show,
    DateField,
    SimpleShowLayout,
} from 'react-admin';

const UserSubscriptionLeaveMarkedShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ReferenceField label="User bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <TextField source="id"/>
                </ReferenceField>
                <ReferenceField label="Subscription" source="subscriptionId" reference="SubscriptionV3">
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source="cookId" />
                <DateField source="date"/>
                <TextField source="slot" />
                <TextField source="markedBy" />
                <TextField source="markedByUserType" />
                <TextField source="leaveType" />
                <TextField source="resolution" />
            </SimpleShowLayout>
        </Show>
    );
};

export default UserSubscriptionLeaveMarkedShow;