import {AuthProvider} from 'react-admin'
import {AuthService} from "./services/AuthService";

const authProvider: AuthProvider = {
    // send username and password to the auth server and get back credentials
    async login(params) {
        const authService = new AuthService();
        let data = await authService.issueToken(params.username, params.password);
        if (data.status !== 200) {
            console.log(data)
            return Promise.reject()
        }
        localStorage.setItem('auth', JSON.stringify(data))
        return Promise.resolve()
    },
    // when the dataProvider returns an error, check if this is an authentication error
    async checkError(error) {
        const status = error.status;
        if (status === 401 || status === 403) {
            const authService = new AuthService();
            let success = await authService.refreshToken();
            if (!success) {
                localStorage.removeItem('auth');
                return Promise.reject();
            }
        }
        return Promise.resolve();
    },
    // chen the user navigates, make sure that their credentials are still valid
    async checkAuth(params) {
        return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject();
    },
    // remove local credentials and notify the auth server that the user logged out
    async logout() {
        localStorage.removeItem('auth');
        return Promise.resolve()
    },
    async getPermissions(params) {
        return Promise.resolve();
    },
};
export default authProvider