import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    DeleteButton,
    ShowButton,
    ReferenceField,
    DateField,
} from 'react-admin';

const UserSubscriptionLeaveMarked = (props: any) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source='id' />
                <ReferenceField label="User bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <TextField source="id"/>
                </ReferenceField>
                <ReferenceField label="Subscription" source="subscriptionId" reference="SubscriptionV3">
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source="cookId" />
                <DateField source="date"/>
                <TextField source="slot" />
                <TextField source="markedBy" />
                <TextField source="markedByUserType" />
                <TextField source="leaveType" />
                <TextField source="resolution" />
                <DeleteButton/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default UserSubscriptionLeaveMarked;