import React from 'react';
import {NumberField, Show, SimpleShowLayout, TextField} from 'react-admin';

const BundleShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id'/>
                <TextField source='name'/>
                <TextField source='paidDays'/> 
                <TextField source='freeDays' />
                <NumberField source='createdBy' />
            </SimpleShowLayout>
        </Show>
    );
};

export default BundleShow;