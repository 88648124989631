import {AppConstant} from "../interfaces/AppConstantsInterface/AppConstant";
import {CreateAppConstant} from "../interfaces/AppConstantsInterface/CreateAppConstant";
import BaseService from "./BaseService";

export class AppConstantService extends BaseService<AppConstant,CreateAppConstant> {
    
    constructor(){
        let url: string = '/app-constant'
        super(url)
    }
    
}