import React from 'react';
import {ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';

const TemplateVariantShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
            <TextField source='id' />
                <TextField source='language' />
                <TextField source='flowId' />
                <ReferenceField label="template" source="template" reference="Template" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source='content'/>
            </SimpleShowLayout>
        </Show>
    );
};

export default TemplateVariantShow;