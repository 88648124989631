import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    DeleteButton,
    ShowButton,
    BooleanField,
    ReferenceField,
    DateField
} from 'react-admin';

const CookSubscriptionV3 = (props: any) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source='id' />
                <BooleanField source="active"/>
                <TextField source='cookId' />
                <ReferenceField label="Subscription" source="subscriptionId" reference="SubscriptionV3">
                    <TextField source='id' />
                </ReferenceField>
                <ReferenceField label="User Bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <TextField source='id' />
                </ReferenceField>
                <DateField source="startDate" />
                <TextField source="startSlot" />
                <DateField source="endDate" />
                <TextField source="endSlot" />
                <TextField source="leaveTaken" />
                <TextField source="leaveAllowed" />
                <DeleteButton/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default CookSubscriptionV3;