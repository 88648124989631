import React from 'react';
import { Create, ReferenceInput, SelectInput, SimpleForm, TextInput, NumberInput } from 'react-admin';

const HouseWalletTransactionCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput label="User Bundle Mapping" reference="UserBundleMapping" source="userBundleMappingId" >
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <ReferenceInput label="Wallet" reference="HouseWallet" source="walletId">
                    <SelectInput optionText="houseId"/>
                </ReferenceInput>
                <ReferenceInput label="Subscription" reference="SubscriptionV3" source="subscriptionId">
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <NumberInput source="amount" />
                <NumberInput source="totalBalanceAfter" />
                <NumberInput source="userId" />
                <TextInput source="reason" />
            </SimpleForm>
        </Create>
    );
};

export default HouseWalletTransactionCreate;