import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    DeleteButton,
    ShowButton,
    ReferenceField,
} from 'react-admin';

const CookSubscriptionCheckInConfiguration = (props: any) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source='id' />
                <ReferenceField label=' Cook Subscription' source='cookSubcriptionId' reference='CookSubscriptionV3'>
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source='minCheckinSelfie' />
                <TextField source='minCheckoutKitchen' />
                <TextField source='minCheckinKitchen' />
                <TextField source='minCheckoutFoodPhoto' />
                <TextField source='isLocationCompulsory' />
                <DeleteButton/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default CookSubscriptionCheckInConfiguration;