import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, DateInput } from 'react-admin';
import { SlotsArray, UserSubscriptionLeaveResolutionArray, UserSubscriptionLeaveTypeArray } from '../../interfaces/CommonInterfaces/Common';

const UserSubscriptionLeaveMarkedCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput label="User bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <ReferenceInput label="Subscription" source="subscriptionId" reference="SubscriptionV3">
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <TextInput source="cookId" />
                <DateInput source="date"/>
                <SelectInput source="slot" choices={ SlotsArray }/>
                <TextInput source="markedBy" />
                <TextInput source="markedByUserType" />
                <SelectInput source="leaveType" choices={ UserSubscriptionLeaveTypeArray }/>
                <SelectInput source="resolution" choices={ UserSubscriptionLeaveResolutionArray }/>
            </SimpleForm>
        </Create>
    );
};

export default UserSubscriptionLeaveMarkedCreate;