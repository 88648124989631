import React from 'react';
import {DateField, ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';

const TempReplacementShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
            <TextField source='id' />
                <ReferenceField label="User Subscription Leave" source="userSubscriptionLeaveId" reference="UserSubscriptionLeaveMarked" link="show">
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceField label="Subscription" source="subscriptionId" reference="SubscriptionV3" link="show">
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceField label="User Bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping" link="show">
                    <TextField source="id" />
                </ReferenceField>
                <TextField source='cookId' />
                <DateField source='date' />
                <TextField source='time' />
                <TextField source='slot' />
                <TextField source='comment' />
                <TextField source='status'/>
            </SimpleShowLayout>
        </Show>
    );
};

export default TempReplacementShow;