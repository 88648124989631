import React from 'react';
import {DateInput, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin'
import { TimeEnumsArray, UserServiceTransactionStateArray } from '../../interfaces/CommonInterfaces/Common';

const UserBundleServiceMappingEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput label="User Service bundle Mapping" source="userServiceBundleMappingId" reference="UserBundleServiceMapping">
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <SelectInput source="state" choices={UserServiceTransactionStateArray}/>
                <DateInput source="date"/>
                <SelectInput source="time" choices={TimeEnumsArray}/>
                <TextInput source="userId" />
                <TextInput source="userType" />
                <TextInput source="commentFromUser" />
                <TextInput source="commentFromAdmin" />
            </SimpleForm>
        </Edit>
    );
};

export default UserBundleServiceMappingEdit;