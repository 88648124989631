import React from 'react';
import {Edit, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin'
import { createdByType, GenderPreference, mealMapping, plan } from '../../interfaces/CommonInterfaces/Common';

const BundleMetaChangeHistoryEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput label="User Bundle" source="userBundleMetaId" reference="UserBundleMeta">
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <SelectInput source='plan' choices={plan}/>
                <SelectInput source='state' choices={[
                    {id: 'PENDING', name: 'PENDING'},
                    {id: 'PROGRESS', name: 'PROGRESS'},
                    {id: 'PROCESSED', name: 'PROCESSED'}
                ]}/>
                <SelectInput source='genderPreference' choices={ GenderPreference }/>
                <SelectInput source='mealMapping' choices={ mealMapping }/>
                <TextInput source='createdBy' />
                <SelectInput source='createByType' choices={createdByType}/>
                <TextInput source='bundlePrice' />
                <TextInput source='totalPeople' />
                <TextInput source='quotedPrice' />
                <TextInput source='totalLeaves' />
                <TextInput source='quotedDiscount' />
                <TextInput source='quotedNetPrice' />
            </SimpleForm>
        </Edit>
    );
};

export default BundleMetaChangeHistoryEdit;