import React from 'react';
import {DateInput, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin'
import { SlotsArray, UserSubscriptionLeaveResolutionArray, UserSubscriptionLeaveTypeArray } from '../../interfaces/CommonInterfaces/Common';

const UserSubscriptionLeaveMarkedEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput label="User bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <ReferenceInput label="Subscription" source="subscriptionId" reference="SubscriptionV3">
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <TextInput source="cookId" />
                <DateInput source="date"/>
                <SelectInput source="slot" choices={ SlotsArray }/>
                <TextInput source="markedBy" />
                <TextInput source="markedByUserType" />
                <SelectInput source="leaveType" choices={ UserSubscriptionLeaveTypeArray }/>
                <SelectInput source="resolution" choices={ UserSubscriptionLeaveResolutionArray }/>
            </SimpleForm>
        </Edit>
    );
};

export default UserSubscriptionLeaveMarkedEdit;