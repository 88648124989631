import React from 'react';
import {Edit, PasswordInput, SimpleForm, TextInput} from 'react-admin';

const AuthAdminUserEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source='name'/>
                <TextInput source='email'/>
                <PasswordInput source='password'/>
            </SimpleForm>
        </Edit>
    );
};

export default AuthAdminUserEdit;