import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    DeleteButton,
    ShowButton,
    ReferenceField
} from 'react-admin';

const Extension = (props: any) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source='id' />
                <ReferenceField label="Subscription" source="subscriptionId" reference="SubscriptionV3">
                    <TextField source="id"/>
                </ReferenceField>
                <ReferenceField label="User Bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source='visits' />
                <ReferenceField label="User Subscription leave Marked" source="userSubscriptionLeaveId" reference="UserSubscriptionLeaveMarked">
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source='reason' />
                <TextField source='comments' />
                <TextField source='status' />
                <TextField source="cancellationReason"/>
                <DeleteButton/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default Extension;