import React from 'react';
import {BooleanField, DateField, ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';

const CookSubscriptionV3Show = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <BooleanField source="active"/>
                <TextField source='cookId' />
                <ReferenceField label="Subscription" source="subscriptionId" reference="SubscriptionV3">
                    <TextField source='id' />
                </ReferenceField>
                <ReferenceField label="User Bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <TextField source='id' />
                </ReferenceField>
                <DateField source="startDate" />
                <TextField source="startSlot" />
                <DateField source="endDate" />
                <TextField source="endSlot" />
                <TextField source="leaveTaken" />
                <TextField source="leaveAllowed" />
            </SimpleShowLayout>
        </Show>
    );
};

export default CookSubscriptionV3Show;