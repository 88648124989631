import React from 'react';
import { Create, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

const CookSubscriptionCheckInConfigurationCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput label=' Cook Subscription' source='cookSubcriptionId' reference='CookSubscriptionV3'>
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <TextInput source='minCheckinSelfie' />
                <TextInput source='minCheckoutKitchen' />
                <TextInput source='minCheckinKitchen' />
                <TextInput source='minCheckoutFoodPhoto' />
                <TextInput source='isLocationCompulsory' />
            </SimpleForm>
        </Create>
    );
};

export default CookSubscriptionCheckInConfigurationCreate;