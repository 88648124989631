import React from 'react';
import {ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';

const CookSubscriptionCheckInConfigurationShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ReferenceField label=' Cook Subscription' source='cookSubcriptionId' reference='CookSubscriptionV3'>
                    <TextField source="cookid"/>
                </ReferenceField>
                <TextField source='minCheckinSelfie' />
                <TextField source='minCheckoutKitchen' />
                <TextField source='minCheckinKitchen' />
                <TextField source='minCheckoutFoodPhoto' />
                <TextField source='isLocationCompulsory' />
            </SimpleShowLayout>
        </Show>
    );
};

export default CookSubscriptionCheckInConfigurationShow;