import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, DateInput } from 'react-admin';

const UserBudleMappingCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source='houseId'/>
                <ReferenceInput label='House' source='bundleId' reference='Bundle'>
                    <SelectInput optionText='name'/>
                </ReferenceInput>
                <TextInput source='bundlePrice' />
                <DateInput source='startDate' />
                <SelectInput source='startSlot' choices={[
                    { id: 'B', name: 'B' },
                    { id: 'L', name: 'L' },
                    { id: 'D', name: 'D' },
                    { id: 'BL', name: 'BL' },
                    { id: 'LD', name: 'LD' },
                    { id: 'BLD', name: 'BLD' },
                ]} />
                <DateInput source='endDate' />
                <SelectInput source='endSlot' choices={[
                    { id: 'B', name: 'B' },
                    { id: 'L', name: 'L' },
                    { id: 'D', name: 'D' },
                    { id: 'BL', name: 'BL' },
                    { id: 'LD', name: 'LD' },
                    { id: 'BLD', name: 'BLD' },
                ]}/>
                <SelectInput source='state' choices={[
                    { id: 'ACTIVE', name: 'ACTIVE' },
                    { id: 'PAUSED', name: 'PAUSED' },
                ]} />
                <DateInput source='pauseDate' />
                <SelectInput source='pauseSlot' choices={[
                    { id: 'B', name: 'B' },
                    { id: 'L', name: 'L' },
                    { id: 'D', name: 'D' },
                    { id: 'BL', name: 'BL' },
                    { id: 'LD', name: 'LD' },
                    { id: 'BLD', name: 'BLD' },
                ]} />
                <SelectInput source='status' choices={[
                    { id: 'PENDING', name: 'PENDING' },
                    { id: 'ACTIVE', name: 'ACTIVE' },
                    { id: 'UPCOMING', name: 'UPCOMING' },
                    { id: 'FULFILLED', name: 'FULFILLED' },
                    { id: 'CLOSED', name: 'CLOSED' },
                    { id: 'CANCELLED', name: 'CANCELLED' }
                ]} />
                <TextInput source='quotedPrice'/>
                <TextInput source='quotedDiscount'/>
                <TextInput source='quotedNetPrice' />
                <TextInput source='createdBy' />
                <SelectInput source='createdByType' choices={[
                    { id: 'ADMIN', name: 'ADMIN' },
                    { id: 'CUSTOMER', name: 'CUSTOMER' },
                    { id: 'COOK', name: 'COOK' },
                    { id: 'INTERNAL_SERVICES', name: 'INTERNAL_SERVICES' }
                ]} />
            </SimpleForm>
        </Create>
    );
};

export default UserBudleMappingCreate;