import React from 'react';
import {Edit, NumberInput, SimpleForm, TextInput} from 'react-admin'

const BundleEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
            <TextInput source='id'/>
                <TextInput source='name'/>
                <TextInput source='paidDays'/> 
                <TextInput source='freeDays' />
                <NumberInput source='createdBy' />
            </SimpleForm>
        </Edit>
    );
};

export default BundleEdit;