import React from 'react';
import {DateInput, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin'
import { SlotsArray, TempReplacementStatusArray, TimeEnumsArray } from '../../interfaces/CommonInterfaces/Common';

const TempReplacementEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput label="User Subscription Leave" source="userSubscriptionLeaveId" reference="UserSubscriptionLeaveMarked">
                    <SelectInput optionText="id" />
                </ReferenceInput>
                <ReferenceInput label="Subscription" source="subscriptionId" reference="SubscriptionV3">
                    <SelectInput optionText="id" />
                </ReferenceInput>
                <ReferenceInput label="User Bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <SelectInput optionText="id" />
                </ReferenceInput>
                <TextInput source='cookId' />
                <DateInput source='date' />
                <SelectInput source='time' choices={TimeEnumsArray}/>
                <SelectInput source='slot' choices={ SlotsArray}/>
                <TextInput source='comment' />
                <SelectInput source='status' choices={ TempReplacementStatusArray }/>
            </SimpleForm>
        </Edit>
    );
};

export default TempReplacementEdit;