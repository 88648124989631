import React from 'react';
import {
    TextField,
    ReferenceField,
    Show,
    DateField,
    SimpleShowLayout,
} from 'react-admin';

const UserBundleServiceMappingShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <ReferenceField label="User bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <TextField source="id"/>
                </ReferenceField>
                <ReferenceField label="Value Added Service" source="vasId" reference="ValueAddedService">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="totalVisits" />
                <DateField source="startDate"/>
                <DateField source="endDate" />
                <TextField source="totalTaken" />
            </SimpleShowLayout>
        </Show>
    );
};

export default UserBundleServiceMappingShow;