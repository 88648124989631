import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const AppConstantsCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name"/>
                <TextInput source="value"/>
            </SimpleForm>
        </Create>
    );
};

export default AppConstantsCreate;