import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, DateInput } from 'react-admin';

const UserBudleServiceMappingCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput label="User bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <ReferenceInput label="Value Added Service" source="vasId" reference="ValueAddedService">
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <TextInput source="totalVisits" />
                <DateInput source="startDate"/>
                <DateInput source="endDate" />
                <TextInput source="totalTaken" />
            </SimpleForm>
        </Create>
    );
};

export default UserBudleServiceMappingCreate;