import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const CreateApplication = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name"/>
                <TextInput source="fcmCredentials" />
                <TextInput source="data" />
            </SimpleForm>
        </Create>
    );
};

export default CreateApplication;