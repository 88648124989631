import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    DeleteButton,
    ShowButton,
    ReferenceField,
    DateField,
} from 'react-admin';

const UserBundleMapping = (props: any) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source='id' />
                <TextField source="houseId"/>
                <ReferenceField label="Bundle" source="bundleId" reference="Bundle">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source='bundlePrice' />
                <DateField source="startDate" />
                <TextField source="startSlot" />
                <DateField source="endDate" />
                <TextField source="endSlot" />
                <TextField source="state"  />
                <DateField source="pauseDate" />
                <TextField source="pauseSlot"  />
                <TextField source="status"  />
                <TextField source="quotedPrice"/>
                <TextField source="quotedDiscount"/>
                <TextField source="quotedNetPrice" />
                <ReferenceField label="createdBy" source="createdBy" reference="Template">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="createdByType" />
                <DeleteButton/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default UserBundleMapping;