import React from 'react';
import { DateInput, Edit, ReferenceInput, SelectInput, SimpleForm } from 'react-admin'
import { SlotsArray, TimeEnumsArray } from '../../interfaces/CommonInterfaces/Common';

const CookSubscriptionSlotMappingEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
            <ReferenceInput label="Cook Subscription" source="cookSubscriptionId" reference="CookSubscriptionV3">
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <SelectInput source='slotName' choices={ SlotsArray }/>
                <SelectInput source='time' choices={ TimeEnumsArray }/>
                <DateInput source='startDate'/>
                <DateInput source='endDate'/>
            </SimpleForm>
        </Edit>
    );
};

export default CookSubscriptionSlotMappingEdit;