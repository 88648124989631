import {
    CreateResult,
    DataProvider,
    fetchUtils,
    GetListParams,
    GetListResult,
    GetOneParams,
    GetOneResult,
    DeleteResult, UpdateResult, DeleteManyResult, GetManyReferenceResult, GetManyResult
} from 'react-admin';
import jsonServerProvider from 'ra-data-json-server'
import {AppConstantService} from "./services/AppConstantService";
import {AppConstant} from "./interfaces/AppConstantsInterface/AppConstant";
import {CreateAppConstant} from "./interfaces/AppConstantsInterface/CreateAppConstant";
import { SMSService } from './services/SMSService';
import { SMSInterface } from './interfaces/SMSInterfaces/SMS';
import { CreateSMSInterface } from './interfaces/SMSInterfaces/CreateSMSInterface';
import { TemplateService } from './services/TemplateService';
import { TemplateVariantService } from './services/TemplateVariantService';
import { CreateTemplateInterface } from './interfaces/TemplateInterfaces/CreateTemplate';
import { TemplateInterface } from './interfaces/TemplateInterfaces/Template';
import { ApplicationService } from './services/ApplicationService';
import { CreateApplicationInterface } from './interfaces/ApplicationInterface/CreateApplicationInterface';
import { ApplicationInterface } from './interfaces/ApplicationInterface/Application';
import { CreateTemplateVariantInterface } from './interfaces/TemplateVariantInterface/CreateTemplateVariant';
import { TemplateVariantInterface } from './interfaces/TemplateVariantInterface/TemplateVariant';
import { BundleService } from './services/BundleService';
import { BundleInterface } from './interfaces/BundleInterface/Bundle';
import { CreateBundleInterface } from './interfaces/BundleInterface/CreateBundle';
import { UserBundleMappingService } from './services/UserBundleMappingService';
import { UserBundleMappingInterface } from './interfaces/UserBundleMappingInterface/UserBundleMapping';
import { CreateUserBundleMappingInterface } from './interfaces/UserBundleMappingInterface/CreateUserBundleMapping';
import { UserBundleMetaService } from './services/UserBundleMetaService';
import { UserBundleMetaInterface } from './interfaces/UserBundleMetaInterface/UserBundleMetaInterface';
import { CreateUserBundleMetaInterface } from './interfaces/UserBundleMetaInterface/CreateUserBundleMetaInterface';
import { SubscriptionV3Service } from './services/subscriptionV3Service';
import { SubscriptionV3Interface } from './interfaces/SubscriptionV3interfaces/subscriptionV3';
import { CreateSubscriptionV3Interface } from './interfaces/SubscriptionV3interfaces/CreateSubscriptionV3Interface';
import { BundleMetaChangeHistoryService } from './services/BundleMetaChangeHistoryService';
import { BundleMetaChangeHistoryInterface } from './interfaces/BundleMetaChangeHistory/BundleMetaChangeHistory';
import { CreateBundleMetaChangeHistoryInterface } from './interfaces/BundleMetaChangeHistory/CreateBundleMetaChangeHistory';
import { HouseWalletService } from './services/HouseWalletService';
import { HouseWalletInterface } from './interfaces/HouseWalletInterfaces/HouseWalletInterface';
import { CreateHouseWalletInterface } from './interfaces/HouseWalletInterfaces/CreateHouseWalletInterface';
import { HouseWalletTransactionService } from './services/HouseWalletTransactionService';
import { HouseWalletTransactionInterface } from './interfaces/HouseWalletTransactionInterfaces/HouseWalletTransactionInterface';
import { CreateHouseWalletTransactionInterface } from './interfaces/HouseWalletTransactionInterfaces/CreateHouseWalletTransaction';
import { ValueAddedService } from './services/ValueAddedService';
import { ValueAddedServiceInterface } from './interfaces/ValueAddedServiceInterface/ValueAddedServiceInterface';
import { CreateValueAddedServiceInterface } from './interfaces/ValueAddedServiceInterface/CreateValueAddedServiceInterface';
import { CreateUserBundleServiceMappingInterface } from './interfaces/UserBundleServiceMappingInterfaces/CreateUserBundleServiceMappingInterface';
import { UserBundleServiceMappingService } from './services/UserBundleServiceMappingService';
import { UserBundleServiceMappingInterface } from './interfaces/UserBundleServiceMappingInterfaces/UserBundleServiceMappingInterface';
import { UserServiceTransactionService } from './services/UserServiceTransactionService';
import { UserServiceTransactionInterface } from './interfaces/UserServiceTransactionInterface/UserServiceTransactionInterface';
import { CreateUserServiceTransactionInterface } from './interfaces/UserServiceTransactionInterface/CreateUserServiceTransactionInterface';
import { CookSubscriptionV3Service } from './services/CookSubscriptionV3Service';
import { CookSubscriptionV3Interface } from './interfaces/CookSubscriptionV3Interfaces/CookSubscriptionV3Interface';
import { CreateCookSubscriptionV3interface } from './interfaces/CookSubscriptionV3Interfaces/CreateCookSubscriptionV3Interface';
import { CookSubscriptionCheckInConfigurationService } from './services/CookSubscriptionCheckInConfigurationService';
import { CookSubscriptionCheckInConfigurationInterface } from './interfaces/CookSubscriptionCheckInConfigurationInterface/CookSubscriptionCheckInConfigurationInterface';
import { CreateCookSubscriptionCheckInConfigurationInterface } from './interfaces/CookSubscriptionCheckInConfigurationInterface/CreateCookSubscriptionCheckInConfigurationInterface';
import { CookSubscriptionSlotMappingService } from './services/CookSubscriptionSlotMappingService';
import { CookSubscriptionSlotMappingInterface } from './interfaces/CookSubscriptionSlotMappingInterface/CookSubscriptionSlotMappingInterface';
import { CreateCookSubscriptionSlotMappingInterface } from './interfaces/CookSubscriptionSlotMappingInterface/CreateCookSubscriptionSlotMappingInterface';
import { UserSubscriptionLeaveMarkedService } from './services/UserSubscriptionLeaveMarkedService';
import { UserSubscriptionLeaveMarkedInterface } from './interfaces/UserSubscriptionLeaveMarkedInterface/UserSubscriptionLeaveMarkedInterface';
import { CreateUserSubscriptionLeaveMarkedInterface } from './interfaces/UserSubscriptionLeaveMarkedInterface/CreateUserSubscriptionLeaveMarkedInterface';
import { TempReplacementService } from './services/TempReplacementService';
import { TempReplacementInterface } from './interfaces/TempReplacementInterface/TempReplacementInterface';
import { CreateTempReplacementInterface } from './interfaces/TempReplacementInterface/CreateTempReplacementInterface';
import { VendorService } from './services/VendorService';
import { VendorInterface } from './interfaces/VendorInterface/Vendorinterface';
import { CreateVendorInterface } from './interfaces/VendorInterface/CreateVendorInterface';
import { ExtensionService } from './services/ExtensionService';
import { ExtensionInterface } from './interfaces/ExtensionInterface/ExtensionInterface';
import { CreateExtensionInterface } from './interfaces/ExtensionInterface/CreateExtensionInterface';
import { AuthAdminService } from './services/AuthAdminUserService';
import { AuthAdminUserInterface } from './interfaces/AuthAdminUserInterface/AuthAdminUserInterface';
import { CreateAuthAdminUserInterface } from './interfaces/AuthAdminUserInterface/CreateAuthAdminUserInterface';

const appConstantService = new AppConstantService();
const smsService = new SMSService();
const templateService = new TemplateService();
const templateVariantService = new TemplateVariantService();
const applicationService = new ApplicationService();
const bundleService = new BundleService();
const userBundleMappingService = new UserBundleMappingService();
const userBundleMetaService = new UserBundleMetaService();
const subscriptionV3Service = new SubscriptionV3Service();
const bundleMetaChangeHistory = new BundleMetaChangeHistoryService();
const houseWalletService = new HouseWalletService();
const houseWalletTransactionService = new HouseWalletTransactionService();
const valueAddedService = new ValueAddedService();
const userBundleServiceMappingService = new UserBundleServiceMappingService();
const userServiceTransactionService = new UserServiceTransactionService()
const cookSubscriptionv3Service = new CookSubscriptionV3Service();
const cookSubscriptionCheckInConfigurationService = new CookSubscriptionCheckInConfigurationService()
const cookSubscriptionSlotMappingService = new CookSubscriptionSlotMappingService();
const userSubscriptionLeaveMarkedService = new UserSubscriptionLeaveMarkedService();
const tempReplacementService = new TempReplacementService();
const vendorService = new VendorService()
const extensionService = new ExtensionService();
const authAdminService = new AuthAdminService();

const apiUrl = process.env.REACT_APP_PUBLIC_URL;

const httpClient = (url: any, options: fetchUtils.Options = {}) => {
    const requestHeaders: Headers = (options.headers ||
        new Headers({
            Accept: 'application/json',
        })) as Headers
    options.headers = requestHeaders
    let authData = localStorage.getItem('auth');
    const { accessToken } = !!authData ? JSON.parse(authData as string).data : '';
    options.headers.set('Authorization', `Bearer ${accessToken}`)
    options.headers.set('X-APP-CODE', 'COOK')

    return fetchUtils.fetchJson(url, options)
}

const dataProvider = jsonServerProvider(
    apiUrl,
    httpClient,
)

const customDataProvider: DataProvider = {
    ...dataProvider,
    getList: async (resource, params: GetListParams): Promise<GetListResult<any>> => {
        let limit = params.pagination.perPage;
        let offset = (params.pagination.page-1)*limit;
        switch (resource) {
            case 'AppConstants':
                return await appConstantService.getAll(limit, offset);
            case 'AuthAdminUsers':
                return await authAdminService.getAll(limit, offset)
            case 'SMS':
                return await smsService.getAll(limit, offset);
            case 'Template':
                return await templateService.getAll(limit, offset);
            case 'TemplateVariant':
                return await templateVariantService.getAll(limit, offset);
            case 'Application':                
                return await applicationService.getAll(limit, offset)
            case 'Bundle':
                return await bundleService.getAll(limit, offset)
            case 'UserBundleMapping':
                return await userBundleMappingService.getAll(limit, offset)
            case 'UserBundleMeta':
                return await userBundleMetaService.getAll(limit, offset)
            case 'SubscriptionV3':
                return await subscriptionV3Service.getAll(limit, offset)
            case 'BundleMetaChangeHistory':
                return await bundleMetaChangeHistory.getAll(limit, offset)
            case 'HouseWallet':
                return await houseWalletService.getAll(limit, offset)
            case 'HouseWalletTransaction':
                return await houseWalletTransactionService.getAll(limit, offset)
            case 'ValueAddedService':
                return await valueAddedService.getAll(limit, offset)
            case 'UserBundleServiceMapping':
                return await userBundleServiceMappingService.getAll(limit, offset)
            case 'UserServiceTransaction':
                return await userServiceTransactionService.getAll(limit, offset)
            case 'CookSubscriptionV3':
                return await cookSubscriptionv3Service.getAll(limit, offset)
            case 'CookSubscriptionCheckInConfiguration':
                return await cookSubscriptionCheckInConfigurationService.getAll(limit, offset)
            case 'CookSubscriptionSlotMapping':
                return await cookSubscriptionSlotMappingService.getAll(limit, offset)
            case 'UserSubscriptionLeaveMarked':
                return await userSubscriptionLeaveMarkedService.getAll(limit, offset)
            case 'TempReplacement':
                return await tempReplacementService.getAll(limit, offset)
            case 'Vendor':
                return await vendorService.getAll(limit, offset)
            case 'Extension':
                return await extensionService.getAll(limit, offset)
            default:
                let defaultRes: GetListResult = { data: [], total: 0 };
                return Promise.resolve(defaultRes);
        }
    },
    getOne: async (resource, params: GetOneParams<any>): Promise<GetOneResult<any>> => {
        switch (resource) {
            case 'AppConstants':
                return await appConstantService.getById(params.id);
            case 'AuthAdminUsers':
                return await authAdminService.getById(params.id);
            case 'SMS':
                return await smsService.getById(params.id);
            case 'Template':
                return await templateService.getById(params.id);
            case 'TemplateVariant':
                return await templateVariantService.getById(params.id)
            case 'Application':
                return await applicationService.getById(params.id);
            case 'Bundle':
                return await bundleService.getById(params.id);
            case 'UserBundleMapping':
                return await userBundleMappingService.getById(params.id);
            case 'UserBundleMeta':
                return await userBundleMetaService.getById(params.id);
            case 'SubscriptionV3':
                return await subscriptionV3Service.getById(params.id);
            case 'BundleMetaChangeHistory':
                return await bundleMetaChangeHistory.getById(params.id);
            case 'HouseWallet':
                return await houseWalletService.getById(params.id);
            case 'HouseWalletTransaction':
                return await houseWalletTransactionService.getById(params.id);
            case 'ValueAddedService':
                return await valueAddedService.getById(params.id);
            case 'UserBundleServiceMapping':
                return await userBundleServiceMappingService.getById(params.id);
            case 'UserServiceTransaction':
                return await userServiceTransactionService.getById(params.id);
            case 'CookSubscriptionV3':
                return await cookSubscriptionv3Service.getById(params.id);
            case 'CookSubscriptionCheckInConfiguration':
                return await cookSubscriptionCheckInConfigurationService.getById(params.id);
            case 'CookSubscriptionSlotMapping':
                return await cookSubscriptionSlotMappingService.getById(params.id);
            case 'UserSubscriptionLeaveMarked':
                return await userSubscriptionLeaveMarkedService.getById(params.id);
            case 'TempReplacement':
                return await tempReplacementService.getById(params.id);
            case 'Vendor':
                return await vendorService.getById(params.id);
            case 'Extension':
                return await extensionService.getById(params.id);
            default:
                let defaultRes: GetOneResult = { data: [] };
                return defaultRes;
        }
    },
    update: async (resource, params): Promise<UpdateResult> => {
        switch (resource) {
            case 'AppConstants':
                return await appConstantService.updateById(params.data as AppConstant);
            case 'AuthAdminUsers':
                return await authAdminService.updateById(params.data as AuthAdminUserInterface);
            case 'SMS':
                return await smsService.updateById(params.data as SMSInterface)
            case 'Template':
                return await templateService.updateById(params.data as TemplateInterface)
            case 'TemplateVariant':
                return await templateVariantService.updateById(params.data as TemplateVariantInterface)
            case 'Application':
                return await applicationService.updateById(params.data as ApplicationInterface)
            case 'Bundle':
                return await bundleService.updateById(params.data as BundleInterface);
            case 'UserBundleMapping':
                return await userBundleMappingService.updateById(params.data as UserBundleMappingInterface);
            case 'UserBundleMeta':
                return await userBundleMetaService.updateById(params.data as UserBundleMetaInterface);
            case 'SubscriptionV3':
                return await subscriptionV3Service.updateById(params.data as SubscriptionV3Interface);
            case 'BundleMetaChangeHistory':
                return await bundleMetaChangeHistory.updateById(params.data as BundleMetaChangeHistoryInterface);
            case 'HouseWallet':
                return await houseWalletService.updateById(params.data as HouseWalletInterface);
            case 'HouseWalletTransaction':
                return await houseWalletTransactionService.updateById(params.data as HouseWalletTransactionInterface);
            case 'ValueAddedService':
                return await valueAddedService.updateById(params.data as ValueAddedServiceInterface);
            case 'UserBundleServiceMapping':
                return await userBundleServiceMappingService.updateById(params.data as UserBundleServiceMappingInterface);
            case 'UserServiceTransaction':
                return await userServiceTransactionService.updateById(params.data as UserServiceTransactionInterface);
            case 'CookSubscriptionV3':
                return await cookSubscriptionv3Service.updateById(params.data as CookSubscriptionV3Interface);
            case 'CookSubscriptionCheckInConfiguration':
                return await cookSubscriptionCheckInConfigurationService.updateById(params.data as CookSubscriptionCheckInConfigurationInterface);
            case 'CookSubscriptionSlotMapping':
                return await cookSubscriptionSlotMappingService.updateById(params.data as CookSubscriptionSlotMappingInterface);
            case 'UserSubscriptionLeaveMarked':
                return await userSubscriptionLeaveMarkedService.updateById(params.data as UserSubscriptionLeaveMarkedInterface);
            case 'TempReplacement':
                return await tempReplacementService.updateById(params.data as TempReplacementInterface);
            case 'Vendor':
                return await vendorService.updateById(params.data as VendorInterface);
            case 'Extension':
                return await extensionService.updateById(params.data as ExtensionInterface);
            default:
                let defaultRes: UpdateResult = { data: {} };
                return defaultRes;
        }
    },
    create: async (resource: any, params: any): Promise<CreateResult> => {
        switch (resource) {
            case 'AppConstants':
                return await appConstantService.create(params.data as CreateAppConstant);
            case 'AuthAdminUsers':
                return await authAdminService.create(params.data as CreateAuthAdminUserInterface);
            case 'SMS':
                return await smsService.create(params.data as CreateSMSInterface);
            case 'Template': 
                return await templateService.create(params.data as CreateTemplateInterface)
            case 'TemplateVariant': 
                return await templateVariantService.create(params.data as CreateTemplateVariantInterface)
            case 'Application':
                return await applicationService.create(params.data as CreateApplicationInterface)
            case 'Bundle':
                return await bundleService.create(params.data as CreateBundleInterface);
            case 'UserBundleMapping':
                return await userBundleMappingService.create(params.data as CreateUserBundleMappingInterface);
            case 'UserBundleMeta':
                return await userBundleMetaService.create(params.data as CreateUserBundleMetaInterface);
            case 'SubscriptionV3':
                return await subscriptionV3Service.create(params.data as CreateSubscriptionV3Interface);
            case 'BundleMetaChangeHistory':
                return await bundleMetaChangeHistory.create(params.data as CreateBundleMetaChangeHistoryInterface);
            case 'HouseWallet':
                return await houseWalletService.create(params.data as CreateHouseWalletInterface);
            case 'HouseWalletTransaction':
                return await houseWalletTransactionService.create(params.data as CreateHouseWalletTransactionInterface)
            case 'ValueAddedService':
                return await valueAddedService.create(params.data as CreateValueAddedServiceInterface)
            case 'UserBundleServiceMapping':
                return await userBundleServiceMappingService.create(params.data as CreateUserBundleServiceMappingInterface)
            case 'UserServiceTransaction':
                return await userServiceTransactionService.create(params.data as CreateUserServiceTransactionInterface)
            case 'CookSubscriptionV3':
                return await cookSubscriptionv3Service.create(params.data as CreateCookSubscriptionV3interface)
            case 'CookSubscriptionCheckInConfiguration':
                return await cookSubscriptionCheckInConfigurationService.create(params.data as CreateCookSubscriptionCheckInConfigurationInterface)
            case 'CookSubscriptionSlotMapping':
                return await cookSubscriptionSlotMappingService.create(params.data as CreateCookSubscriptionSlotMappingInterface)
            case 'UserSubscriptionLeaveMarked':
                return await userSubscriptionLeaveMarkedService.create(params.data as CreateUserSubscriptionLeaveMarkedInterface)
            case 'TempReplacement':
                return await tempReplacementService.create(params.data as CreateTempReplacementInterface)
            case 'Vendor':
                return await vendorService.create(params.data as CreateVendorInterface)
            case 'Extension':
                return await extensionService.create(params.data as CreateExtensionInterface)
            default:
                let defaultRes: CreateResult = { data: {} };
                return defaultRes;
        }
    },
    delete: async (resource, params): Promise<DeleteResult> => {
        let res: DeleteResult = {data: params.previousData};
        switch (resource) {
            case 'AppConstants':
                await appConstantService.deleteById(res.data.id);
                return res;
            case 'AuthAdminUsers':
                await authAdminService.deleteById(res.data.id);
                return res;
            case 'SMS':
                await smsService.deleteById(res.data.id);
                return res;
            case 'Template':
                await templateService.deleteById(res.data.id)
                return res;
            case 'TemplateVariant':
                await templateVariantService.deleteById(res.data.id)
                return res;
            case 'Application':
                await applicationService.deleteById(res.data.id)
                return res;
            case 'Bundle':
                await bundleService.deleteById(res.data.id)
                return res;
            case 'UserBundleMapping':
                await userBundleMappingService.deleteById(res.data.id);
                return res;
            case 'UserBundleMeta':
                await userBundleMetaService.deleteById(res.data.id);
                return res;  
            case 'SubscriptionV3':
                await subscriptionV3Service.deleteById(res.data.id);
                return res;  
            case 'BundleMetaChangeHistory':
                await bundleMetaChangeHistory.deleteById(res.data.id);
                return res;  
            case 'HouseWallet':
                await houseWalletService.deleteById(res.data.id);
                return res;  
            case 'HouseWalletTransaction':
                await houseWalletTransactionService.deleteById(res.data.id);
                return res;
            case 'ValueAddedService':
                await valueAddedService.deleteById(res.data.id);
                return res;
            case 'UserBundleServiceMapping':
                await userBundleServiceMappingService.deleteById(res.data.id);
                return res;
            case 'UserServiceTransaction':
                await userServiceTransactionService.deleteById(res.data.id);
                return res;
            case 'CookSubscriptionV3':
                await cookSubscriptionv3Service.deleteById(res.data.id);
                return res;
            case 'CookSubscriptionCheckInConfiguration':
                await cookSubscriptionCheckInConfigurationService.deleteById(res.data.id);
                return res;
            case 'CookSubscriptionSlotMapping':
                await cookSubscriptionSlotMappingService.deleteById(res.data.id);
                return res;
            case 'UserSubscriptionLeaveMarked':
                await userSubscriptionLeaveMarkedService.deleteById(res.data.id);
                return res;
            case 'TempReplacement':
                await tempReplacementService.deleteById(res.data.id);
                return res;
            case 'Vendor':
                await vendorService.deleteById(res.data.id);
                return res;
            case 'Extension':
                await extensionService.deleteById(res.data.id);
                return res;
            default:
                let defaultRes: DeleteResult = { data: {} };
                return defaultRes;
        }
    },
    deleteMany: async (resource, params): Promise<DeleteManyResult> => {
        let res: DeleteManyResult = {data: []}
        switch (resource) {
            case 'AppConstants':
                for (const id of params.ids) {
                    await appConstantService.deleteById(id as number);
                }
                return res;
            case 'AuthAdminUsers':
                for (const id of params.ids) {
                    await authAdminService.deleteById(id as number);
                }
                return res;
            case 'SMS':
                for (const id of params.ids) {
                    await smsService.deleteById(id as number);
                }
                return res;
            case 'Template':
                for (const id of params.ids) {
                    await templateService.deleteById(id as number);
                }
                return res;
            case 'TemplateVariant':
                for (const id of params.ids) {
                    await templateVariantService.deleteById(id as number);
                }
                return res;
            case 'Application':
                for (const id of params.ids) {
                    await applicationService.deleteById(id as number);
                }
                return res;
            case 'Bundle':
                for (const id of params.ids) {
                    await bundleService.deleteById(id as number);
                }
                return res;
            case 'UserBundleMapping':
                for (const id of params.ids) {
                    await userBundleMappingService.deleteById(id as number);
                }
                return res;
            case 'UserBundleMeta':
                for (const id of params.ids) {
                    await userBundleMetaService.deleteById(id as number);
                }
                return res;
            case 'SubscriptionV3':
                for (const id of params.ids) {
                    await subscriptionV3Service.deleteById(id as number);
                }
                return res;
            case 'BundleMetaChangeHistory':
                for (const id of params.ids) {
                    await bundleMetaChangeHistory.deleteById(id as number);
                }
                return res;
            case 'HouseWallet':
                for (const id of params.ids) {
                    await houseWalletService.deleteById(id as number);
                }
                return res;
            case 'HouseWalletTransaction':
                for (const id of params.ids) {
                    await houseWalletTransactionService.deleteById(id as number);
                }
                return res;
            case 'ValueAddedService':
                for (const id of params.ids) {
                    await valueAddedService.deleteById(id as number);
                }
                return res;
            case 'UserBundleServiceMapping':
                for (const id of params.ids) {
                    await userBundleServiceMappingService.deleteById(id as number);
                }
                return res;
            case 'UserServiceTransaction':
                for (const id of params.ids) {
                    await userServiceTransactionService.deleteById(id as number);
                }
                return res;
            case 'CookSubscriptionV3':
                for (const id of params.ids) {
                    await cookSubscriptionv3Service.deleteById(id as number);
                }
                return res;
            case 'CookSubscriptionCheckInConfiguration':
                for (const id of params.ids) {
                    await cookSubscriptionCheckInConfigurationService.deleteById(id as number);
                }
                return res;
            case 'CookSubscriptionSlotMapping':
                for (const id of params.ids) {
                    await cookSubscriptionSlotMappingService.deleteById(id as number);
                }
                return res;
            case 'UserSubscriptionLeaveMarked':
                for (const id of params.ids) {
                    await userSubscriptionLeaveMarkedService.deleteById(id as number);
                }
                return res;
            case 'TempReplacement':
                for (const id of params.ids) {
                    await tempReplacementService.deleteById(id as number);
                }
                return res;
            case 'Vendor':
                for (const id of params.ids) {
                    await vendorService.deleteById(id as number);
                }
                return res;
            case 'Extension':
                for (const id of params.ids) {
                    await extensionService.deleteById(id as number);
                }
                return res;
            default:
                let defaultRes: DeleteResult = { data: {} };
                return defaultRes;
        }
    },
    getMany: async (resource, params): Promise<GetManyResult<any>> => {
        let res: GetManyResult = {
            data: []
        }
        switch (resource) {
            case 'AppConstants':
                for (const id of params.ids) {
                    let data = await appConstantService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'AuthAdminUsers':
                for (const id of params.ids) {
                    let data = await authAdminService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'SMS':
                for (const id of params.ids) {
                    let data = await smsService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'Template':
                for (const id of params.ids) {
                    let data = await templateService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'TemplateVariant':
                for (const id of params.ids) {
                    let data = await templateVariantService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'Application':
                for (const id of params.ids) {
                    let data = await applicationService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'Bundle':
                for (const id of params.ids) {
                    let data = await bundleService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'UserBundleMapping':
                for (const id of params.ids) {
                    let data = await userBundleMappingService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'UserBundleMeta':
                for (const id of params.ids) {
                    let data = await userBundleMetaService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'SubscriptionV3':
                for (const id of params.ids) {
                    let data = await subscriptionV3Service.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'BundleMetaChangeHistory':
                for (const id of params.ids) {
                    let data = await bundleMetaChangeHistory.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'HouseWallet':
                for (const id of params.ids) {
                    let data = await houseWalletService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'HouseWalletTransaction':
                for (const id of params.ids) {
                    let data = await houseWalletTransactionService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'ValueAddedService':
                for (const id of params.ids) {
                    let data = await valueAddedService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'UserBundleServiceMapping':
                for (const id of params.ids) {
                    let data = await userBundleServiceMappingService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'UserServiceTransaction':
                for (const id of params.ids) {
                    let data = await userServiceTransactionService.getById(parseInt(id as string));
                    res.data.push(data)
                }
                return res;
            case 'CookSubscriptionV3':
                for (const id of params.ids) {
                    let data = await cookSubscriptionv3Service.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'CookSubscriptionCheckInConfiguration':
                for (const id of params.ids) {
                    let data = await cookSubscriptionCheckInConfigurationService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'CookSubscriptionSlotMapping':
                for (const id of params.ids) {
                    let data = await cookSubscriptionSlotMappingService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'UserSubscriptionLeaveMarked':
                for (const id of params.ids) {
                    let data = await userSubscriptionLeaveMarkedService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'TempReplacement':
                for (const id of params.ids) {
                    let data = await tempReplacementService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'Vendor':
                for (const id of params.ids) {
                    let data = await vendorService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            case 'Extension':
                for (const id of params.ids) {
                    let data = await extensionService.getById(parseInt(id as string));
                    res.data.push(data.data)
                }
                return res;
            default:
                let defaultRes: GetManyResult = { data: [] };
                return defaultRes;
        }
    },
    getManyReference: async (resource, params): Promise<GetManyReferenceResult<any>> => {
        let res: GetManyReferenceResult = {
            data: []
        }
        return res;
    }
}

export default customDataProvider;