import React from 'react';
import {Edit, SimpleForm, TextInput} from 'react-admin'

const AppConstantsEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="name"/>
                <TextInput source="value"/>
            </SimpleForm>
        </Edit>
    );
};

export default AppConstantsEdit;