import React from 'react';
import {ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';

const HouseWalletTransactionShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <ReferenceField label="User Bundle Mapping" reference="UserBundleMapping" source="userBundleMappingId" >
                    <TextField source="status"/>
                </ReferenceField>
                <ReferenceField label="Wallet" reference="HouseWallet" source="walletId">
                    <TextField source="amount"/>
                </ReferenceField>
                <ReferenceField label="Subscription" reference="SubscriptionV3" source="subscriptionId">
                    <TextField source="status"/>
                </ReferenceField>
                <TextField source="amount" />
                <TextField source="totalBalanceAfter" />
                <TextField source="userId" />
                <TextField source="reason" />
            </SimpleShowLayout>
        </Show>
    );
};

export default HouseWalletTransactionShow;