import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    DeleteButton,
    ShowButton,
    ReferenceField,
    DateField
} from 'react-admin';

const CookSubscriptionSlotMapping = (props: any) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source='id' />
                <ReferenceField label="Cook Subscription" source="cookSubscriptionId" reference="CookSubscriptionV3">
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source='slotName' />
                <TextField source='time' />
                <DateField source='startDate'/>
                <DateField source='endDate'/>
                <DeleteButton/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default CookSubscriptionSlotMapping;