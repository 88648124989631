import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const ValueAddedServiceCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source='name' />
                <TextInput source='description' />
            </SimpleForm>
        </Create>
    );
};

export default ValueAddedServiceCreate;