import React from 'react';
import {Edit, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin'

const TemplateVariantEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source='language' />
                <TextInput source='flowId' />
                <ReferenceInput label="Template" source="template" reference="Template">
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <TextInput source='content'/>
            </SimpleForm>
        </Edit>
    );
};

export default TemplateVariantEdit;