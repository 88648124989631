import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const TemplateCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name" />
                <ReferenceInput label="App" source="app" reference="Application">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <SelectInput source="SMSType" choices={[
                    { id: 'PROMOTIONAL', name: 'PROMOTIONAL' },
                    { id: 'TRANSACTIONAL', name: 'TRANSACTIONAL' },
                ]} />
                <SelectInput source="SMSPriority" choices={[
                    { id: 'LOW', name: 'LOW' },
                    { id: 'NORMAL', name: 'NORMAL' },
                    { id: 'HIGH', name: 'HIGH'}
                ]} />
            </SimpleForm>
        </Create>
    );
};

export default TemplateCreate;