import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const SMSCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="houseId" />
                <TextInput source="amount" />
            </SimpleForm>
        </Create>
    );
};

export default SMSCreate;