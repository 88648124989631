import React from 'react';
import {Edit, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin'

const TemplateEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
            <TextInput source="name" />
                <ReferenceInput label="App" source="app" reference="Application">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <SelectInput source="SMSType" choices={[
                    { id: 'PROMOTIONAL', name: 'PROMOTIONAL' },
                    { id: 'TRANSACTIONAL', name: 'TRANSACTIONAL' },
                ]} />
                <SelectInput source="SMSPriority" choices={[
                    { id: 'LOW', name: 'LOW' },
                    { id: 'NORMAL', name: 'NORMAL' },
                    { id: 'HIGH', name: 'HIGH'}
                ]} />
            </SimpleForm>
        </Edit>
    );
};

export default TemplateEdit;