import React from 'react';
import {ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';

const BundleMetaChangeHistoryShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
            <TextField source='id'/>
                <ReferenceField label="User Bundle" source="userBundleMetaId" reference="UserBundleMeta">
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source='plan'/> 
                <TextField source='genderPreference' />
                <TextField source='mealMapping' />
                <TextField source='createdBy' />
                <TextField source='createByType' />
                <TextField source='state' />
                <TextField source='bundlePrice' />
                <TextField source='quotedPrice' />
                <TextField source='totalLeaves' />
                <TextField source='totalPeople' />
                <TextField source='quotedDiscount' />
                <TextField source='quotedNetPrice' />
            </SimpleShowLayout>
        </Show>
    );
};

export default BundleMetaChangeHistoryShow;