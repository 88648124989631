import React from 'react';
import {
    TextField,
    ReferenceField,
    Show,
    DateField,
    SimpleShowLayout,
} from 'react-admin';

const UserBundleServiceMappingShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <ReferenceField label="User Service bundle Mapping" source="userServiceBundleMappingId" reference="UserBundleServiceMapping">
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source="state" />
                <DateField source="date"/>
                <TextField source="time" />
                <TextField source="userId" />
                <TextField source="userType" />
                <TextField source="commentFromUser" />
                <TextField source="commentFromAdmin" />
            </SimpleShowLayout>
        </Show>
    );
};

export default UserBundleServiceMappingShow;