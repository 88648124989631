import React from 'react';
import {Show, SimpleShowLayout, TextField} from 'react-admin';

const ApplicationShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='name'/>
                <TextField source='name' />
                <TextField source='fcmCredentials' />
                <TextField source='data'/>
            </SimpleShowLayout>
        </Show>
    );
};

export default ApplicationShow;