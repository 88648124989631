import {CreateResult, DeleteResult, GetListResult, GetOneResult, RaRecord, UpdateResult} from "react-admin";
import {PaginatedInterface} from "../interfaces/AppConstantsInterface/PaginatedInterface";
import HttpClient from "../HttpClient";

export default class BaseService<T extends RaRecord, CreateT>{
    private url:string
    constructor(url:string){
        this.url = url
    }

    async getAll(limit: number, offset: number): Promise<GetListResult<T>> {
        let data: { data: PaginatedInterface<T> } = await HttpClient.get(this.url + '/all', { params: { limit, offset } });
        let res: GetListResult = {
            data: data.data.data,
            total: data.data.count
        };
        return res;
    }

    async getById(id: number): Promise<GetOneResult<T>> {
        let data: {data: T} = await HttpClient.get(this.url + `/${id}`);
        let res: GetOneResult = {data: data.data};
        return res;
    }

    async updateById(data: T): Promise<UpdateResult<T>> {
        await HttpClient.patch(this.url+`/${data.id}`, data);
        let res: UpdateResult = {data: data};
        return res;
    }

    async create(constantData: CreateT): Promise<CreateResult<T>> {
        let data: {data: T} = await HttpClient.post(this.url, constantData);
        let res: CreateResult = { data: data.data };
        return res;
    }

    async deleteById(id: number): Promise<DeleteResult<T>> {
        await HttpClient.delete(this.url+`/${id}`);
        let res: DeleteResult = {data: {id}}
        return res;
    }
}