import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const TemplateVariantCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source='language' />
                <TextInput source='flowId' />
                <ReferenceInput label="Template" source="template" reference="Template">
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <TextInput source='content'/>
            </SimpleForm>
        </Create>
    );
};

export default TemplateVariantCreate;