import React from 'react';
import {Show, SimpleShowLayout, TextField} from 'react-admin';

const ApplicationShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <TextField source='houseId'/>
                <TextField source='amount'/>
            </SimpleShowLayout>
        </Show>
    );
};

export default ApplicationShow;