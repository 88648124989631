import React from 'react';
import { DateField, ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';

const CookSubscriptionSlotMappingShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <ReferenceField label="Cook Subscription" source="cookSubscriptionId" reference="CookSubscriptionV3">
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source='slotName' />
                <TextField source='time' />
                <DateField source='startDate'/>
                <DateField source='endDate'/>
            </SimpleShowLayout>
        </Show>
    );
};

export default CookSubscriptionSlotMappingShow;