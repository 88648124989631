import React from 'react';
import {Edit, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin'

const SMSEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source='body' />
                <ReferenceInput label="App" source="app" reference="Application">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source='receiver' />
                <ReferenceInput label="Template Variant" source="templateVariant" reference="TemplateVariant">
                    <SelectInput optionText="content" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export default SMSEdit;