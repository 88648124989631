import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    DeleteButton,
    ShowButton,
    ReferenceField,
    DateField,
} from 'react-admin';

const UserBundleServiceMapping = (props: any) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source='id' />
                <ReferenceField label="User bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <TextField source="id"/>
                </ReferenceField>
                <ReferenceField label="Value Added Service" source="vasId" reference="ValueAddedService">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="totalVisits" />
                <DateField source="startDate"/>
                <DateField source="endDate" />
                <TextField source="totalTaken" />
                <DeleteButton/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default UserBundleServiceMapping;