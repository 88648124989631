import React from 'react';
import {
    SimpleShowLayout,
    TextField,
    ReferenceField,
    Show,
} from 'react-admin';

const UserBundleMappingShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <ReferenceField label="User Bundle" source="userBundleMappingId" reference="UserBundleMapping">
                    <TextField source="status"/>
                </ReferenceField> 
                <TextField source='plan' />
                <TextField source="genderPreference" />
                <TextField source="totalPeople" />
                <TextField source="mealMapping" />
                <TextField source="totalVisits" />
                <TextField source="totalLeaves"  />
                <TextField source="takenLeaves" />
                <TextField source="utensils"  />
            </SimpleShowLayout>
        </Show>
    );
};

export default UserBundleMappingShow;