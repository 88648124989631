import HttpClient from "../HttpClient";

export class AuthService {
    private readonly clientId: string;
    private readonly clientSecret: string;
    private url: string = '/auth/v2/token';

    constructor() {
        this.clientId = process.env.REACT_APP_CLIENT_ID as string;
        this.clientSecret = process.env.REACT_APP_CLIENT_SECRET as string;
    }

    async issueToken(username: string, password: string) {
        const urlEncodedData = new URLSearchParams();
        urlEncodedData.append('username', username);
        urlEncodedData.append('password', password);
        urlEncodedData.append('scope', 'ADMIN');
        urlEncodedData.append('grant_type', 'password');

        return await HttpClient.post(
            this.url,
            urlEncodedData,
            {
                auth: {
                    username: this.clientId,
                    password: this.clientSecret
                },
                headers: {'content-type': 'application/x-www-form-urlencoded'}
            }
        )
    }

    async refreshToken() {
        let authData = JSON.parse(localStorage.getItem('auth') as string);
        const urlEncodedData = new URLSearchParams();

        urlEncodedData.append('refresh_token', authData.data.refreshToken);
        urlEncodedData.append('grant_type', 'refresh_token');

        let data = await HttpClient.post(
            this.url,
            urlEncodedData,
            {
                auth: {
                    username: this.clientId,
                    password: this.clientSecret
                },
                headers: {'content-type': 'application/x-www-form-urlencoded'}
            }
        )
        if (!!data && data.status === 200) {
            localStorage.setItem('auth', JSON.stringify(data))
            return true;
        }
        return false;
    }
}