import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    DeleteButton,
    ShowButton
} from 'react-admin';

const SMS = (props: any) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source='id' />
                <TextField source='houseId'/>
                <TextField source='amount'/>
                <DeleteButton/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default SMS;