import React from 'react';
import {DateInput, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin'

const UserBundleServiceMappingEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput label="User bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <ReferenceInput label="Value Added Service" source="vasId" reference="ValueAddedService">
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <TextInput source="totalVisits" />
                <DateInput source="startDate"/>
                <DateInput source="endDate" />
                <TextInput source="totalTaken" />
            </SimpleForm>
        </Edit>
    );
};

export default UserBundleServiceMappingEdit;