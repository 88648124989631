import React from 'react';
import { Create, PasswordInput, SimpleForm, TextInput } from 'react-admin';

const AuthAdminUserCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source='name'/>
                <TextInput source='email'/>
                <PasswordInput source='password'/>
            </SimpleForm>
        </Create>
    );
};

export default AuthAdminUserCreate;