import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    DeleteButton,
    ShowButton,
    ReferenceField,
} from 'react-admin';

const SMS = (props: any) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source='id'/>
                <TextField source='data'/>
                <TextField source='body'/> 
                <ReferenceField label="app" source="app" reference="Application">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source='receiver' />
                <TextField source='status' />
                <TextField source='priority' />
                <TextField source='sender' />
                <TextField source='sid' />
                <ReferenceField label="Template-Variant" source="templateVariant" reference="TemplateVariant">
                    <TextField source="flowId"/>
                </ReferenceField>
                <DeleteButton/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default SMS;