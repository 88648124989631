import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const SMSCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source='body' multiline/>
                <ReferenceInput label="App" source="app" reference="Application">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source='receiver' />
                <ReferenceInput label="Template Variant" source="templateVariant" reference="TemplateVariant">
                    <SelectInput optionText="content" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export default SMSCreate;