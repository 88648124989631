import React from 'react';
import { ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';

const ExtensionShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <ReferenceField label="Subscription" source="subscriptionId" reference="SubscriptionV3">
                    <TextField source="id"/>
                </ReferenceField>
                <ReferenceField label="User Bundle Mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <TextField source="id"/>
                </ReferenceField> 
                <TextField source='visits' />
                <ReferenceField label="User Subscription leave Marked" source="userSubscriptionLeaveId" reference="UserSubscriptionLeaveMarked">
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source='reason' />
                <TextField source='comments' />
                <TextField source='status' />
                <TextField source="cancellationReason"/>
            </SimpleShowLayout>
        </Show>
    );
};

export default ExtensionShow;