import React from 'react';
import { Edit, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin'

const UserBundleMappingEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput label="user-bundle-mapping" source="userBundleMappingId" reference="UserBundleMapping">
                    <SelectInput optionText="id"/>
                </ReferenceInput> 
                <SelectInput source="plan" choices={[
                    { id: 'PREMIUM', name: 'PREMIUM' },
                    { id: 'NORMAL', name: 'NORMAL' }
                ] }/>
                <SelectInput source="genderPreference" choices={[
                    { id: 'MALE', name: 'MALE' },
                    { id: 'FEMALE', name: 'FEMALE' }
                ] }/>
                <TextInput source="totalPeople" />
                <SelectInput source="mealMapping" choices={[
                    { id: 'B', name: 'B' },
                    { id: 'L', name: 'L' },
                    { id: 'D', name: 'D' },
                    { id: 'BL', name: 'BL' },
                    { id: 'LD', name: 'LD' },
                    { id: 'BLD', name: 'BLD' },
                ]} />
                <TextInput source="totalVisits" />
                <TextInput source="totalLeaves"  />
                <TextInput source="takenLeaves" />
                <TextInput source="utensils"  />
            </SimpleForm>
        </Edit>
    );
};

export default UserBundleMappingEdit;