import React from 'react';
import {ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';

const ApplicationShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
            <TextField source='id'/>
                <TextField source='data'/>
                <TextField source='body'/> 
                <ReferenceField label="app" source="app" reference="Application">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source='receiver' />
                <TextField source='status' />
                <TextField source='priority' />
                <TextField source='sender' />
                <TextField source='sid' />
                <ReferenceField label="Template-Variant" source="templateVariant" reference="TemplateVariant">
                    <TextField source="flowId"/>
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    );
};

export default ApplicationShow;