import React from 'react';
import { Create, NumberInput, SimpleForm, TextInput } from 'react-admin';

const BundleCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source='name'/>
                <TextInput source='paidDays'/> 
                <TextInput source='freeDays' />
                <NumberInput source='createdBy' />
            </SimpleForm>
        </Create>
    );
};

export default BundleCreate;