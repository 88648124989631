import React from 'react';
import {Edit, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin'

const CookSubscriptionV3Edit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput label=' Cook Subscription' source='cookSubcriptionId' reference='CookSubscriptionV3'>
                    <SelectInput optionText="id"/>
                </ReferenceInput>
                <TextInput source='minCheckinSelfie' />
                <TextInput source='minCheckoutKitchen' />
                <TextInput source='minCheckinKitchen' />
                <TextInput source='minCheckoutFoodPhoto' />
                <TextInput source='isLocationCompulsory' />
            </SimpleForm>
        </Edit>
    );
};

export default CookSubscriptionV3Edit;