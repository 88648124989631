import React from 'react';
import {
    TextField,
    ReferenceField,
    Show,
    DateField,
    SimpleShowLayout,
} from 'react-admin';

const UserBundleMappingShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <TextField source="houseId"/>
                <ReferenceField label="House" source="bundleId" reference="Template">
                    <TextField source="address"/>
                </ReferenceField>
                <TextField source='bundlePrice' />
                <DateField source="startDate" />
                <TextField source="startSlot" />
                <DateField source="endDate" />
                <TextField source="endSlot" />
                <TextField source="state"  />
                <DateField source="pausedDate" />
                <TextField source="pauseSlot"  />
                <TextField source="status"  />
                <TextField source="quotedPrice"/>
                <TextField source="quotedDiscount"/>
                <TextField source="quotedNetPrice" />
                <ReferenceField label="createdBy" source="createdBy" reference="Template">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="createdByType" />
            </SimpleShowLayout>
        </Show>
    );
};

export default UserBundleMappingShow;